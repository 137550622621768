<template>
    <div class="sub-title-box">
        <div class="title-content">
            <div class="title">{{title}}<div class="label"  v-if="enTitle"></div></div>
            
            <div class="en-title" v-if="enTitle">{{enTitle}}</div>
            <div class="second-desc" v-if="secondDesc">{{secondDesc}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["title","enTitle","secondDesc"]
}
</script>
<style lang="less" scoped>
.sub-title-box {
    .title-content {
        font-weight: bold;
        font-size: 30px;
        color: #1A232F;
        line-height: 42px;
        position: relative;
        display: flex;
        z-index: 3;
        .en-title {
            font-weight: bold;
            font-size: 24px;
            color: #979DB1;
            line-height: 32px;
            text-transform: uppercase;
            margin-left: 28px;
            margin-top: 20px;
        }
        .title {
            position: relative;
            z-index: 3;
        }
        .label {
            position: absolute;
            width: 28px;
            height: 28px;
            background: #CCCFDA;
            right: -20px;
            bottom: 0px;
            z-index: -1;
        }
        .second-desc {
            font-weight: normal;
            color: #626A83;
            font-size: 16px;
            margin-left: 20px;
        }
    }
}
</style>