<template>
    <div class="banner-box">
        <div class="banner-content">
            <div class="banner-left">
                <!-- <div class="desc">
                    STRATEGY IN SIXTY SECONDS, 
                    <div>RISE ABOVE!</div>
                </div> -->
                <div class="info" style="margin-top:60px;">
                    PC普及的进程中，行业应用推动了各个行业的效率飞跃。
                    <br>
                    <br>
                    AI正在经历相似的阶段，通用模型强大，但还未深入到行业需求。
                    <br>
                    <br>
                    时升智能大数据+AI解决方案覆盖决策、产品、市场三大模块。
                    <br>
                    <br>
                    全面帮助企业降本增效
                </div>
                


                <!-- <div class="name">时升数据科技</div>
                <div class="info">        
                    通过大数据助力企业60秒高效决策
                    <br>
                    实现0!=1的飞跃,阶乘式增长！
                </div>
                <div class="name">时升AI</div>
                <div class="sub-info">打造消费者需求与工业生产的直接通<br>道，助力企业精准创新！</div> -->
            </div>
            <div class="banner-right">
                <div class="banner-img">
                    <img src="@/assets/images/index/banner.png">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less" scoped>
.banner-box {
    width: 100%;
    height: 555px;
    background: #F6F5F8;
    .banner-content {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        height: 100%;
        .banner-left {
            text-align: left;
            .desc {
                font-weight: bold;
                font-size: 36px;
                color: #3672FD;
                line-height: 47px;
                margin-top: 60px;
                width: 556px;
                div {
                    display: flex;
                    justify-content: flex-end;
                }
            }
            .name {
                font-weight: bold;
                font-size: 30px;
                color: #1A232F;
                line-height: 42px;
                margin-top: 30px;
            }
            .info {
                font-weight: 400;
                font-size: 24px;
                color: #1A232F;
                line-height: 32px;
            }
            .sub-info {
                font-weight: 300;
                font-size: 30px;
                color: #1A232F;
                line-height: 42px;
                margin-top: 30px;
            }
        }
        .banner-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            .banner-img {
                img {
                    width: 600px;
                    height: 516px;
                }
            }
        }
    }
}
</style>