<template>
    <div class="solution-box">
        <div class="solution-center">
            <div class="top-header">
                <SubTitle :title="title" :enTitle="enTitle"></SubTitle>
                <!-- <div class="btn cursor" @click="goAbout">其他行业前去定制</div> -->
            </div>
            <div class="solution-content">
                <div class="solution-detail">
                    <div class="detail">
                        <div class="detail-left">
                            <img style="width:532px;height:296px;" src="@/assets/images/amjjz.png">
                        </div>
                        <div class="detail-right">
                            <div>大国工匠非遗文化呈现平台</div>
                        </div>
                        <!-- <div class="opt">
                            <a class="btn cursor" href="http://dev.web.meiaogold.com/" target="_blank">查看案例</a>
                        </div> -->
                        
                    </div>
                    <div class="solution-info">
                        <div class="name">
                            黄金商城平台
                        </div>
                        <div class="desc">
                            我们为黄金行业量身打造数字化解决方案，构建创新黄金商城平台，旨在打破黄金投资与工业用途的传统界限，实现两者高效融合。与非遗工艺师进行合作，用传统古法工艺来弘扬中国传统文化。</div>
                    </div>
                    <div class="solution-info mt-40">
                        <div class="name">
                            黄金行业AI
                        </div>
                        <div class="desc">
                            探索全新黄金定制时代！我们利用先进的AI技术，为您打造独一无二的私人定制黄金体验。无论是精美饰品还是个性化投资佳品，都能满足您的独特品味与需求。让AI设计引领潮流，为您的黄金选择增添无限可能！
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>
<script>
    import SubTitle from "../subTitle/index.vue";
    import ToyBi from "@/components/toy/bi.vue";
    import ToyAbility from "@/components/toy/ability.vue";
    export default {
        components: {
            SubTitle,
            ToyBi,
            ToyAbility
        },
        data() {
            return {
                title: "成功案例",
                // enTitle: "solution",
                enTitle: ""
            }
        },
        methods: {
            goAbout() {
                this.$router.push({
                    path: "about?anchorTarget=contact"
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .solution-box {
        width: 100%;
        background: #fff;

        .solution-center {
            width: 1200px;
            display: flex;
            margin: 0 auto;
            flex-direction: column;
            padding-bottom: 60px;
        }

        .btn {
            width: 198px;
            height: 56px;
            background: #3672FD;
            border-radius: 10px;
            font-weight: bold;
            font-size: 20px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        

        .top-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 50px 0;


        }

        .solution-content {
            display: flex;

            .solution-detail {
                border: 1px solid #f2f2f2;
                display: flex;
                width: 100%;
                flex-direction: column;
                padding-bottom: 40px;

                .detail {
                    display: flex;
                    margin: 0 80px;
                    margin-top: 30px;
                    /* height: 160px; */
                    font-size: 20px;
                    color: #626A83;
                    line-height: 30px;

                    .detail-left {
                        width: 40%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }

                    .detail-right {
                        width: 60%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .opt {
                        display: flex;
                        align-items: center;
                        a {
                            text-decoration: none;
                        }
                    }
                }

                .solution-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 60px;

                    .name {
                        font-weight: bold;
                        font-size: 17px;
                        color: #1A232F;
                        line-height: 24px;
                        text-align: left;
                    }

                    .desc {
                        font-weight: 400;
                        font-size: 14px;
                        color: #626A83;
                        line-height: 20px;
                        margin-top: 7px;
                        text-align: left;
                    }
                }


            }
        }
    }
</style>