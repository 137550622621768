<template>
    <div class="solution-box">
        <div class="solution-center">
            <div class="top-header">
                <SubTitle :title="title" :enTitle="enTitle" secondDesc="基于企业已有的私域流量做个性化定制"></SubTitle>
                <div class="btn cursor" @click="goAbout">其他行业前去定制</div>
            </div>
            <SecondTitle secTitle="数字化商城" secondDesc=""></SecondTitle>
            <div class="desc">我们会针对您的需求和用户偏好，帮助您搭建隶属于您的个性化的商城，帮助您从流量变成留量，并且形成私域流量闭环。</div>
            <img class="mt-20" src="@/assets/images/common/szhsc.png">
            <!-- <SecondTitle class="mt-40" secTitle="专属AI服务工具" secondDesc="快速打造企业专属面向目标用户的AI工具。"></SecondTitle> -->
            <div class="desc">通过AI大模型让每个用户按照自己的描述来表达需求和偏好，并直接以产品效果图的形式提供给厂家进行加工定制，大幅提升定制产品的效率。本方案适合珠宝首饰、高定模玩、企业纪念品等行业，为企业提供与客户进行产品定制对话的便捷通道。</div>
            <img class="mt-20" src="@/assets/images/common/icon_ai.png">
        </div>
        
    </div>
</template>
<script>
    import SubTitle from "../subTitle/index.vue";
import SecondTitle from "@/components/secondTitle/index.vue";
export default {
    components: {
        SubTitle,
        SecondTitle
    },
    data() {
        return {
            title: "私域流量个性化定制解决方案",
            secTitle: "数字化商城",
            secondDesc: "",
            // enTitle: "solution",
            enTitle: ""
        }
    },
    methods: {
        goAbout() {
            this.$router.push({
                path: "about?anchorTarget=contact"
            })
        }
    }
}
</script>
<style lang="less" scoped>
.solution-box {
    width: 100%;
    background: #fff;
    .solution-center {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        padding-bottom: 60px;
        .desc {
            margin-top: 30px;
            text-align: left;
        }
    }
    .top-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 50px 0;
        .btn {
            width: 198px;
            height: 56px;
            background: #3672FD;
            border-radius: 10px;
            font-weight: bold;
            font-size: 20px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .solution-content {
        display: flex;
        justify-content: space-between;
        
    }
}
</style>