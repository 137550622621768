<template>
    <div class="second-title">
        <img v-if="titleImg" :src="titleImg">
        <span v-else></span>
        {{secTitle}} <div class="second-desc" v-if="secondDesc">{{secondDesc}}</div>
    </div>
</template>
<script>
export default {
    props: ["secTitle","titleImg","secondDesc"]
}
</script>
<style lang="less" scoped>
.second-title {
    font-weight: bold;
    font-size: 24px;
    color: #1A232F;
    line-height: 33px;
    display: flex;
    align-items: center;
    img {
        /* width: 100px; */
        height: 30px;
        margin-right: 10px;
    }
    span {
        width: 6px;
        height: 21px;
        background: #3672FD;
        border-radius: 3px;
        margin-right: 10px;
    }
    .second-desc {
        font-weight: normal;
        color: #626A83;
        font-size: 16px;
        margin-left: 20px;
    }
}
</style>