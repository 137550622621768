import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token';
let domain = "sssrise.com";
if(document.domain.indexOf(domain) < 0) {
  domain = document.domain;
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, {path: "/", domain: domain });
  
}

export function removeToken() {
  return Cookies.remove(TokenKey, { path: '/', domain: domain }); // removed!
}
