<template>
    <div class="banner-box">
        <div class="banner-content">
            <div class="banner-title">为什么使用时升智能
                <!-- <div class="sub">（以及目的）</div> -->
            </div>
            <div class="banner-desc">科学决策-降低经营风险/精准预测-把握市场脉搏/降本增效-提升运营能力</div>
            <div class="banner-btn">
                <div class="btn cursor" @click="goAbout">立即体验</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        goAbout() {
            this.$router.push({
                path: "about?anchorTarget=contact"
            })
        }
    }
}
</script>
<style lang="less" scoped>
.banner-box {
    width: 100%;
    height: 555px;
    background: url("~@/assets/images/ai/banner.png") center center no-repeat;
    background-size: 100% 100%;
    .banner-content {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        height: 100%;
        flex-direction: column;
        .banner-title {
            font-weight: bold;
            font-size: 60px;
            color: #FFFFFF;
            line-height: 84px;
            text-align: center;
            margin-top: 112px;
            .sub {
                font-size: 30px;
            }
        }
        .banner-desc {
            font-weight: 400;
            font-size: 36px;
            color: rgba(255,255,255,0.7);
            /* line-height: 33px; */
            /* letter-spacing: 10px; */
            text-align: center;
            font-style: normal;
            margin-top: 32px;
        }
        .banner-btn {
            margin-top: 64px;
            display: flex;
            justify-content: center;
            .btn {
                width: 263px;
                height: 68px;
                border-radius: 10px;
                border: 2px solid #FFFFFF;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-size: 28px;
                color: #FFFFFF;
            }
        }
    }
}
</style>