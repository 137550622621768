<template>
    <div class="bottom-box">
        <div class="bottom-content">
            <!-- <div class="logo">
                <img class="cursor" src="@/assets/images/logoAI_02.png" @click="goIndex">
            </div>
            <div class="info">
                <div class="menu-list">
                    <div class="menu-item" v-for="(item,idx) in menuList" :key="idx" @click="goAbout(item.target)">
                        {{item.name}}
                    </div>
                </div>
                <div class="copyright">
                    <div>© 2024上海时升数据科技有限公司</div>
                    <div>备案信息: 沪ICP备2024042917号</div>
                </div>
            </div>
            <div class="code-box">
                <div class="code-list">
                    <div class="code-item" v-for="(item,idx) in codeList" :key="idx">
                        <div class="desc">{{item.name}}</div>
                        <img :src="item.imgSrc">
                    </div>
                </div>
            </div> -->
            <div class="bottom-detail">
                <div class="copyright">
                    沪ICP备2024042917号
                </div>
                <div class="bottom-item cursor" v-for="(item,idx) in bottomList" :key="idx" @click="goAbout(item.target)">{{item.name}}</div>
                <el-popover class="code-item" placement="top" width="280" trigger="click">
                    <div class="">
                        <img style="260px;height:260px;margin: 0 auto;" src="@/assets/images/about/qiyewx.png">
                    </div>
                    <div slot="reference" class="ability-btn cursor">企业微信</div>
                </el-popover>
                <el-popover class="code-item" placement="top" width="280" trigger="click">
                    <div class="">
                        <img style="260px;height:260px;margin: 0 auto;" src="@/assets/images/gongzhonghao.jpg">
                    </div>
                    <div slot="reference" class="ability-btn cursor">公众号</div>
                </el-popover>
                <el-popover class="code-item" placement="top" width="280" trigger="click">
                    <div class="">
                        <img src="@/assets/images/about/wechat_3.jpg">
                    </div>
                    <div slot="reference" class="ability-btn cursor">AI小程序</div>
                </el-popover>
               
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                bottomList: [{
                    name: "关于我们",
                    target: "about"
                },{
                    name: "联系我们",
                    target: "contact"
                }
                // ,{
                //     name: "工作机会",
                //     target: "join"
                // }
                ],
                menuList: [{
                    name: "关于我们",
                    target: "about"
                },{
                    name: "联系我们",
                    target: "contact"
                },
                // {
                //     name: "工作机会",
                //     target: "join"
                // }
            ],
                codeList: [
                //     {
                //     name: "企业微信",
                //     imgSrc: require("@/assets/images/about/wechat_1.png")
                // },{
                //     name: "公众号",
                //     imgSrc: require("@/assets/images/about/wechat_2.png")
                // },
                {
                    name: "AI小程序",
                    imgSrc: require("@/assets/images/about/wechat_3.jpg")
                }]
            }
        },
        methods: {
            goPath() {
                this.$router.push({
                    path: "About"
                })
            },
            goAbout(target) {
                this.$router.push({
                    path: "about?anchorTarget=" + target
                })
            },
            goIndex() {
                this.$router.push({
                    path: "Index"
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .bottom-box {
        width: 100%;
        /* height: 280px; */
        height: 60px;
        background: #1A232F;
        .bottom-content {
            width: 1200px;
            display: flex;
            margin: 0 auto;
            height: 100%;
            justify-content: center;
            .bottom-detail {
                display: flex;
                align-items: center;
                color: #fff;
                
                .copyright {
                    display: flex;
                    
                }
                .bottom-item {
                    margin-left: 30px;
                }
                .code-item {
                    margin-left: 30px;
                }
            }
            .logo {
                padding-right: 45px;
                img {
                    width: 240px;
                    height: 200px;
                }
            }
            .info {
                display: flex;
                flex-direction: column;
                .menu-list {
                    display: flex;
                    margin-top: 63px;
                    .menu-item {
                        font-weight: 400;
                        font-size: 20px;
                        color: #979DB1;
                        line-height: 28px;
                        padding: 0 30px;
                        cursor: pointer;
                    }
                }
                .copyright {
                    font-weight: 400;
                    font-size: 14px;
                    color: #626A83;
                    line-height: 24px;
                    margin-top: 47px;
                    text-align: left;
                    padding-left: 30px;
                }
            }
            .code-box {
                display: flex;
                flex: 1;
                justify-content: flex-end;
                .code-list {
                    display: flex;
                    margin-top: 70px;
                    
                    .code-item {
                        display: flex;
                        flex-direction: column;
                        margin-right: 30px;
                        &:last-child{
                            margin-right: 0;
                        }
                        .desc {
                            font-weight: 400;
                            font-size: 14px;
                            color: #979DB1;
                            line-height: 20px;
                        }
                        img {
                            width: 96px;
                            height: 96px;
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }
</style>