<template>
  <div class="product-box">
    <div class="product-center">
      <SubTitle :title="title" :enTitle="enTitle">
      </SubTitle>
      <div class="product-list">
        <div class="product-item"
          v-for="(item,idx) in productList" :key="idx">
          <div class="product-img">
            <!-- <img :src="item.imgSrc"> -->
            <img
              :src="item.imgSrc">
          </div>
          <div class="product-info">
            <SecondTitle :secTitle="item.name">
            </SecondTitle>
            <!-- <div class="type">{{item.model_name}}</div> -->
            <!-- <div class="desc-title">模型描述</div> -->
            <div class="desc">{{item.desc}}</div>
            <div class="opt" v-if="item.type ==1 ">
              <!-- <div class="btn">立即体验</div> -->
              <el-popover placement="top" width="280"
                trigger="click">
                <div class="">
                  <img
                    src="@/assets/images/about/wechat_3.jpg">
                </div>
                <div slot="reference" class="btn">立即体验</div>
              </el-popover>
            </div>
            <div class="opt" v-else-if="item.type != 2">
              <div class="btn cursor" @click="goPath(item.type)">立即体验</div>
            </div>
          </div>
          <!-- <div class="product-img product-img-right"
            v-if="idx % 2 == 1">
            <img
              :src="require('@/assets/images/ai/ai_2.png')">
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import SubTitle from "../subTitle/index.vue";
import SecondTitle from "@/components/secondTitle/index.vue";
export default {
  props: ["modelsList"],
  components: {
    SubTitle,
    SecondTitle,
  },
  data() {
    return {
      title: "AI产品",
      // enTitle: "AI products",
      enTitle: "",
      productList: [
        {
          name: "亲缘智伴（KIN AI）",
          typeName: "模型：文心一言4.0&GPT4.0",
          desc: "我们构建了一个以用户需求为核心，实现生产者、消费者与创意者无缝连接、协同共创的产品生态系统。该产品为各位的宝贝量身打造全方位、个性化的育儿指导方案，借助AI的智慧力量，不仅为玩具赋予新的指导和玩法，助力各位家长科学育儿，轻松应对各种育儿挑战！同时挖掘用户的痛点和需求点，使玩具厂商能够更准确地理解并满足核心用户的需求，让爱与智慧同行，陪伴宝贝健康成长！",
          imgSrc: require("@/assets/images/common/kenai.jpg"),
          type: 1
        },
        {
          name: "IP分析工具",
          typeName: "模型：文心一言4.0&GPT4.0",
          desc: "运用先进的AI技术，全面而深入地提供IP综合信息，为您精准定位市场。它不仅建议适合该IP的玩具品类，还分析流量指数趋势，揭示IP热度变化，助您制定有效的营销策略。此外，通过竞品IP对比与品类销售分析，让您轻松实现IP价值的最大化，抢占市场先机！",
          imgSrc: require("@/assets/images/common/ip_fenxi.png"),
          type: 3,
        },
        {
          name: "玩具创意师",
          typeName: "模型：GPT4.0",
          desc: "您的创意灵感源泉！我们提供富有创意、前瞻性的设计思路，结合先进的AI技术，为您快速生成独一无二的玩具创意设计图。",
          imgSrc: require("@/assets/images/common/wjcy.png"),
          type: 1
        }
        ,{
            name: "玩具爆款打造",
            typeName: "模型：ChatGPT 4.0",
            desc: "通过先进的大数据算法和人工智能的结合，对玩具产品进行多维度的分析，并提供科学的数据支持。",
            imgSrc: require("@/assets/images/common/bkdz.png"),
            type: 5
        }
        ,{
            name: "AI私人定制助手",
            typeName: "模型：ChatGPT 4.0",
            desc: "探索全新黄金定制时代，借助先进的AI技术，我们精心为您打造独一无二的私人定制黄金体验。让AI设计成为潮流的引领者，为您的黄金选择开启无限可能，让每一款黄金作品都闪耀着个性化的光芒，彰显您的非凡品味与独特魅力",
            imgSrc: require("@/assets/images/common/srdz.png"),
            type: 2
        }
      ],
 
    };
  },
  methods: {
    goPath(type) {
      switch(type) {
        case 3: 
          window.open("http://bi.sssrise.com/#/ipInfo","_blank");
          // window.location.href="http://bi.sssrise.com/#/ipInfo";
          break;
        case 5:
          window.open("http://bi.sssrise.com/#/hotAi","_blank");
          break;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.product-box {
  width: 100%;
  margin-top: 60px;
  .product-center {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;

    .product-list {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      .product-item {
        display: flex;
        margin-bottom: 80px;
        cursor: pointer;
        .product-img {
          padding-right: 30px;
          img {
            /* width: 600px; */
            /* height: 340px; */
            /* width: 512px;
            height: 512px; */
            width: 256px;
            height: 256px;
            border-radius: 10px;
          }
        }
        .product-img-right {
          padding-left: 30px;
          padding-right: 0;
        }
        .product-info {
          display: flex;
          flex-direction: column;
          flex: 1;
          .type {
            font-weight: bold;
            font-size: 17px;
            color: #1a232f;
            line-height: 24px;
            margin-top: 13px;
            padding-left: 16px;
            text-align: left;
          }
          .desc-title {
            font-weight: bold;
            font-size: 14px;
            color: #626a83;
            line-height: 20px;
            margin-top: 26px;
            padding-left: 16px;
            text-align: left;
          }
          .desc {
            font-weight: 400;
            font-size: 18px;
            color: #626a83;
            line-height: 20px;
            text-align: justify;
            font-style: normal;
            margin-top: 10px;
            padding-left: 16px;
          }
          .opt {
            display: flex;
            /* justify-content: flex-end; */
            flex: 1;
            align-items: flex-end;
            .btn {
              width: 150px;
              height: 40px;
              background: #3672fd;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              font-size: 17px;
              color: #ffffff;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>