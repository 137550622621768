<template>
    <div class="ability-box">
        <SecondTitle :secTitle="secTitle" :titleImg="titleImg"></SecondTitle>
        <div class="ability-list">
            <div class="ability-item" v-for="(item,idx) in abilityList" :key="idx">
                <div class="ability-left" v-if="idx % 2 == 0">
                    <div class="ability-img">
                        <img :src="item.imgSrc">
                    </div>
                </div>
                <div class="ability-right">
                    <div class="name">
                        {{item.name}}
                    </div>
                    <div class="desc">{{item.desc}}</div>
                </div>
                <div class="ability-left ability-img-right" v-if="idx % 2 == 1">
                    <div class="ability-img">
                        <img :src="item.imgSrc">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="ability-opt">
            <el-popover placement="top" width="280" trigger="click">
                <div class="">
                    <img src="@/assets/images/about/wechat_3.jpg">
                </div>
                <div slot="reference" class="ability-btn">立即体验</div>
            </el-popover>

        </div> -->
    </div>
</template>
<script>
    import SecondTitle from "@/components/secondTitle/index.vue";
    export default {
        components: {
            SecondTitle
        },
        data() {
            return {
                secTitle: "行业AI——产品创意助手",
                titleImg: require("@/assets/images/logoAI_02.png"),
                abilityList: [{
                    name: "玩具创意师",
                    desc: "通过AI大模型按照客户需求、产品亮点、IP元素、成本控制等要素智能创意玩具产品。",
                    imgSrc: require("@/assets/images/index/shiyongcelue.png")
                }, {
                    name: "玩具爆款打造",
                    desc: "通过先进的大数据算法与人工智能相结合，针对投产前的玩具进行全方面和多维度的分析，并且提供数据支持和相关建议",
                    imgSrc: require("@/assets/images/index/moxing.png")
                }, {
                    name: "亲缘智伴（KIN AI）",
                    desc: "为玩具企业提供家庭育儿AI解决方案，快速打造企业专属面向目标用户的AI工具，通过AI小程序、种草内容、神奇创意和育儿科学，快速打造和转化私域流量，形成完整闭环。",
                    imgSrc: require("@/assets/images/index/wanju.png")
                }]
            }
        }
    }
</script>
<style lang="less" scoped>
    .ability-box {
        width: 600px;
        display: flex;
        flex-direction: column;
        background: #F8F8FA;
        border-radius: 10px;
        padding: 30px 30px 40px 30px;

        .ability-list {
            display: flex;
            flex-direction: column;

            .ability-item {
                display: flex;
                padding: 35px 0;
                border-bottom: 1px solid #CCCFDA;

                &:last-child {
                    border-bottom: none;
                }

                .ability-left,
                .ability-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .ability-left {
                    padding-right: 30px;
                }

                .ability-img-right {
                    padding-right: 0;
                    padding-left: 30px;
                }

                .name {
                    font-weight: bold;
                    font-size: 17px;
                    color: #1A232F;
                    line-height: 24px;
                    text-align: left;
                }

                .desc {
                    font-weight: 400;
                    font-size: 14px;
                    color: #626A83;
                    line-height: 20px;
                    margin-top: 7px;
                    text-align: left;
                }

                .ability-img {
                    img {
                        width: 95px;
                        height: 95px;
                    }
                }
            }
        }

        .ability-opt {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            .ability-btn {
                width: 150px;
                height: 40px;
                background: #FFFFFF;
                border-radius: 10px;
                border: 1px solid #979DB1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                font-size: 17px;
                color: #626A83;
                cursor: pointer;
            }

        }
    }
</style>