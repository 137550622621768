<template>
  <div class="header-box">
    <div class="header-content">
      <div class="logo"><img class="cursor" src="@/assets/images/index/logo.png" @click="goIndex"></div>
      <!-- <div class="menu-list">
        <div class="menu-item" :class="[menuIdx == idx ? 'active' : '']" v-for="(item,idx) in menuList" :key="idx"
          @click="goPath(idx,item.pathName)">{{item.name}}
        </div>
      </div> -->
      <el-menu :default-active="menuIdx.toString()" class="el-menu-demo menu-list" mode="horizontal" @select="handleSelect">
        <MenuCom :menuList="menuList" :menuIdx="menuIdx.toString()"></MenuCom>
      </el-menu>

    </div>
    <div class="user-box" style="display: none;">
      <div class="avatar-box" v-if="!isLogin" @click="goLogin">
        <img class="cursor" src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
      </div>
      <div class="avatar-box" v-else>
        <img v-if="userInfo && userInfo.avatar" :src="userInfo.avatar">
        <img v-else src="https://files.sssrise.com/files/uploads/static/default_avatar.png">
      </div>
      <div class="user-info" v-if="isLogin" @click="showLoginout = !showLoginout">
        <div class="info-left cursor">
          <div class="name cursor">
            {{userInfo && userInfo.nickname || ""}}
            <!-- <img
              src="https://files.sssrise.com/files/uploads/static/cyhz.png">
            <img
              src="https://files.sssrise.com/files/uploads/static/zshz.png">
            <img
              src="https://files.sssrise.com/files/uploads/static/xzhz.png"> -->
          </div>
          <!-- <div class="vip">
            <div class="vip-level cursor">VIP
              {{userInfo && userInfo.vip_level || 0}}
            </div>
          </div> -->
        </div>
        <!-- <div class="info-right">
          <div class="point-item cursor">
            <div class="desc">我的收入</div>
            <div class="count">
              {{(userInfo && userInfo.income ? parseFloat(userInfo.income / 100).toFixed(0) : 0 )}}
            </div>
          </div>
          <div class="point-item cursor">
            <div class="desc">我的积分</div>
            <div class="count">
              {{userInfo && userInfo.coin || 0}}</div>
          </div>
        </div> -->
        <div class="info-popup" v-if="showLoginout">
          <el-button type="text" @click.stop="loginout">退出登录</el-button>
        </div>
      </div>
      <div class="user-info" v-else>
        <div class="login-btn cursor" @click="goLogin">
          登录/注册</div>
      </div>
    </div>
    <div class="login-wrap" v-if="showLogin">
      <div class="login-layer"></div>
      <LoginCom @closeLogin="closeLogin"></LoginCom>
    </div>

  </div>
</template>

<script>
  import LoginCom from "@/components/login/index.vue";
  import MenuCom from "./menu.vue";
  import { getToken,removeToken } from '@/utils/auth'
  export default {
    components: {
      LoginCom,
      MenuCom
    },
    data() {
      return {
        menuList: [
          {
            name: "首页",
            pathName: "Index",
            index: "1",
          },
          {
            name: "企业服务",
            pathName: "Service",
            index: "2",
            // children: [{
            //   name: "大数据助力企业决策提升",
            //   pathName: "",
            //   subName: "",
            //   index: "2-1",
            // },{
            //   name: "综合报告",
            //   pathName: "",
            //   subName: "report",
            //   index: "2-2",
            // },{
            //   name: "产品策略",
            //   pathName: "",
            //   subName: "strategy",
            //   index: "2-3",
            // },{
            //   name: "IP分析",
            //   pathName: "",
            //   subName: "ip",
            //   index: "2-4",
            // }]
          },
          {
            name: "人工智能",
            pathName: "Intelligent",
            index: "3"
          },
          // {
          //   name: "创意社区",
          //   pathName: "Community",
          //   index: "4"
          // },
          {
            name: "关于我们",
            pathName: "About",
            index: "5",
            // children: [{
            //   name: "公司介绍",
            //   pathName: "",
            //   subName: "about",
            //   index: "5-1",
            // },{
            //   name: "加入时升",
            //   pathName: "",
            //   subName: "join",
            //   index: "5-2",
            // },{
            //   name: "联系我们",
            //   pathName: "",
            //   subName: "contact",
            //   index: "5-3",
            // }]
          },
        ],
        menuIdx: "1",
        isLogin: getToken() ? true : false,
        showLogin: false,
        userInfo: {
          "id": "",
          "nickname": "",
          "mobile": "",
          "avatar": "",
          "gender": "",
          "desc": "",
          "real_name": "",
          "character": "",
          "vip_level": 0,
          "vip_expires_in": "",
          "coin": 0,
          "income": 0,
          "balance": 0,
          "freeze": 0,
          "token": ""
        },
        showLoginout: false
      };
    },
    created() {
      console.log("router", this.$route);
      let routeName = this.$route.name;
      this.menuIdx = this.menuList.findIndex((res) => {
        return res.pathName == routeName;
      });
      this.menuIdx = this.menuIdx + 1;
      if (getToken()) {
        this.getUserInfo();
      }
    },
    watch: {
      $route() {
        let routeName = this.$route.name;
        this.menuIdx = this.menuList.findIndex((res) => {
          return res.pathName == routeName;
        });
        this.menuIdx = this.menuIdx + 1;
      }
    },
    methods: {
      handleSelect(val,indexPath) {
        console.log("val",val,"indexPath",indexPath)
        let tempItem = null;
        if(indexPath.length == 1) {
          tempItem = this.menuList.filter((res) => {
            return res.index == val;
          })
          if(tempItem && tempItem.length > 0) {
            this.goPath(val,tempItem[0].pathName);
          }
        }
        if(indexPath.length == 2) {
          tempItem = this.menuList.filter((res) => {
            return res.index == indexPath[0];
          })
          
          if(tempItem && tempItem.length > 0) {

            let subItem = tempItem[0].children.filter((res) => {
              return res.index == val;
            })
            console.log("subItem",subItem)
            if(subItem && subItem.length > 0) {
              // this.goPath(indexPath[0],tempItem[0].pathName);
              this.$router.push({
                  path: tempItem[0].pathName + "?anchorTarget=" + subItem[0].subName
              })
            }
            
          }
        }
      },
      getUserInfo() {
        this.$store
          .dispatch("GetInfo", "")
          .then((res) => {
            this.userInfo = res;
          })
          .catch((err) => {
            this.$message.error((err && err.msg) || "服务器出错，请稍后重试");
            if(err.error == "20001") {
              this.isLogin = false;
              removeToken();
            }
          });
      },
      goPath(idx, pathName) {
        if (this.menuIdx == idx) {
          return;
        }
        this.menuIdx = idx;
        this.$router.push({
          name: pathName,
        });
      },
      goLogin() {
        this.showLogin = true;
      },
      closeLogin() {
        this.showLogin = false;
        this.isLogin = getToken() ? true : false;
        if (this.isLogin) {
          this.getUserInfo();
          this.$bus.$emit("initData", true);
        }
      },
      goIndex() {
        this.$router.push({
          path: "Index"
        })
      },
      loginout() {
        removeToken();
        this.isLogin = getToken() ? true : false;
      }
    },
  };
</script>
<style lang="less" scoped>
  .header-box {
    min-width: 1190px;
    height: 128px;
    background: #ffffff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;

    .header-content {
      width: 1190px;
      display: flex;
      align-items: center;
      margin: 0 auto;

      .logo {
        display: flex;
        align-items: center;

        img {
          width: 132px;
          height: 100px;
        }
      }

      .menu-list {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        height: 128px;
        border: none;

        .menu-item {
          padding: 0 32px;
          height: 128px;
          font-weight: 400;
          font-size: 20px;
          color: #1a232f;
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;

          &.active {
            color: #3672fd !important;

            &::after {
              position: absolute;
              left: 40px;
              right: 40px;
              height: 4px;
              background: #3672fd;
              border-radius: 2px;
              content: "";
              bottom: 40px;
            }
          }
        }
      }
    }

    .user-box {
      display: flex;
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      align-items: center;

      .avatar-box {
        margin-right: 10px;

        img {
          width: 70px;
          height: 70px;
          border-radius: 100%;
        }
      }

      .user-info {
        display: flex;
        position: relative;
      }
      .info-popup {
        position: absolute;
        left: 0;
        right: 0;
        top: 60px;
        background: #fff;
        z-index: 999;
        height: 40px;
      }

      .login-btn {
        width: 100px;
        height: 36px;
        background: #3672fd;
        box-shadow: 0px 2px 4px 0px #d9e5ff;
        border-radius: 18px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .info-left {
        flex: 1;
        margin-right: 10px;

        .name {
          font-weight: bolder;
          font-size: 17px;
          color: #000000;
          line-height: 24px;
          text-align: left;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 22px;

            &:first-child {
              margin-left: 6px;
            }
          }
        }

        .vip {
          display: flex;

          .vip-level {
            min-width: 48px;
            padding: 0 6px;
            height: 20px;
            background: linear-gradient(135deg, #626a83 0%, #1a232f 100%);
            border-radius: 0px 10px 10px 10px;
            font-weight: normal;
            font-size: 14px;
            color: #eccd8e;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .info-right {
        display: flex;
        flex-direction: column;

        .point-item {
          display: flex;
          padding-right: 30px;
          align-items: center;

          .desc {
            font-weight: 400;
            font-size: 12px;
            color: #979db1;
            line-height: 17px;
          }

          .count {
            font-weight: bold;
            font-size: 18px;
            color: #3672fd;
            line-height: 21px;
            margin-left: 6px;
          }
        }
      }
    }

    .login-wrap {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 999;

      .login-layer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }
</style>